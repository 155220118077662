exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-logg-jsx": () => import("./../../../src/pages/logg.jsx" /* webpackChunkName: "component---src-pages-logg-jsx" */),
  "component---src-templates-competition-jsx": () => import("./../../../src/templates/competition.jsx" /* webpackChunkName: "component---src-templates-competition-jsx" */),
  "component---src-templates-competition-level-jsx": () => import("./../../../src/templates/competition-level.jsx" /* webpackChunkName: "component---src-templates-competition-level-jsx" */),
  "component---src-templates-ground-jsx": () => import("./../../../src/templates/ground.jsx" /* webpackChunkName: "component---src-templates-ground-jsx" */),
  "component---src-templates-match-jsx": () => import("./../../../src/templates/match.jsx" /* webpackChunkName: "component---src-templates-match-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-person-jsx": () => import("./../../../src/templates/person.jsx" /* webpackChunkName: "component---src-templates-person-jsx" */),
  "component---src-templates-season-competition-jsx": () => import("./../../../src/templates/season-competition.jsx" /* webpackChunkName: "component---src-templates-season-competition-jsx" */),
  "component---src-templates-season-competition-players-jsx": () => import("./../../../src/templates/season-competition-players.jsx" /* webpackChunkName: "component---src-templates-season-competition-players-jsx" */),
  "component---src-templates-season-jsx": () => import("./../../../src/templates/season.jsx" /* webpackChunkName: "component---src-templates-season-jsx" */),
  "component---src-templates-season-players-jsx": () => import("./../../../src/templates/season-players.jsx" /* webpackChunkName: "component---src-templates-season-players-jsx" */),
  "component---src-templates-table-jsx": () => import("./../../../src/templates/table.jsx" /* webpackChunkName: "component---src-templates-table-jsx" */),
  "component---src-templates-team-jsx": () => import("./../../../src/templates/team.jsx" /* webpackChunkName: "component---src-templates-team-jsx" */),
  "slice---src-components-layout-slice-jsx": () => import("./../../../src/components/layout-slice.jsx" /* webpackChunkName: "slice---src-components-layout-slice-jsx" */)
}

